'use strict';

angular.module("aerosApp")
	.factory('orgContactModal', [ "$uibModal","CountriesListService", function ($uibModal, CountriesListService) {
		function open(contactTypes, contact, canSetDefault, isViewOnly) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/org/modal/orgContactModal.html",
				controller: "orgContactModalCtrl",
				resolve: {
					contactTypes: function () { return contactTypes; },
					contact: function () { return contact; },
					canSetDefault: function () { return canSetDefault; },
					isViewOnly: function () { return isViewOnly; }
				}
			});
			return modal.result;
		}
		return {
			open: open
		};
	}])
	.controller("orgContactModalCtrl", 
	[ "$scope", "$uibModalInstance", "contactTypes", "contact", "canSetDefault", "isViewOnly", "CountriesListService",
		function($scope, $uibModalInstance, contactTypes, contact, canSetDefault, isViewOnly, CountriesListService) {
			var modalTitle = "Add a New Contact";
			if (isViewOnly){
				modalTitle = "View Contact Details";
			} else if (contact) {
				modalTitle = "Edit Contact";
			}
			$scope.modalTitle = modalTitle;
			$scope.contactTypes = contactTypes;
			$scope.canSetDefault = canSetDefault;
			$scope.isViewOnly = isViewOnly;
			$scope.formData = {
				contact: contact ? contact : {}
			};
			$scope.toggleDefault = function () {
				$scope.formData.contact.defaultContact = !$scope.formData.contact.defaultContact;
			};
            $scope.countries = CountriesListService.getCountries();
			$scope.cancel = function() {
				$uibModalInstance.dismiss();
			};
			$scope.save = function() {
				$uibModalInstance.close($scope.formData);
			};
		}
	]);